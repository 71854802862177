import FqApi from '@/services/fq-api'

export default {
  async getImageIds(quoteId) {
    const url = `/quotes/images/ids/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getImageThumbnail(id, size) {
    const url = `/quotes/images/thumbnail/${id}?size=${size}`
    const result = await FqApi.get(url, { responseType: 'arraybuffer' })
    const image = Buffer.from(result.data, 'binary').toString('base64')
    return `data:${result.headers['content-type'].toLowerCase()};base64,${image}`
  },
  async getImageThumbnailWithInfo(id, size) {
    const url = `/quotes/images/thumbnailinfo/${id}?size=${size}`
    const result = await FqApi.get(url)
    result.data.imageContent = `data:${result.data.mimeType};base64,${result.data.imageContent}`
    return result.data
  },
  async getQuoteImageCategories() {
    const url = '/quotes/images/categories/list'
    return (await FqApi.get(url)).data
  },
  async getImageIdsWithCategory(quoteId,categoryId) {
    const url = `/quotes/images/ids/${quoteId}/${categoryId}`
    const result = await FqApi.get(url)
    return result.data
  }
}
